<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<!--
				<CrudToolbar @create="openNew" @delete="confirmDeleteSelected" @export="exportCSV($event)"/>
				-->
				<CrudTable ref="storeList" :entities="entities"
					:meta="metaStoreTable"
					@tableAction="onTableAction"
					@itemAction="onItemAction"/>
					<!--
				<DataTable ref="dt" :value="entities" v-model:selection="selectedEntity" dataKey="id" :showGridlines=true :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">{{$t('user.list_title')}}</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Search..." />
              </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="email" :header="$t('user.email')" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('user.email')}}</span>
							{{slotProps.data.email}}
						</template>
					</Column>
					<Column field="fullName" :header="$t('user.full_name')" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('user.full_name')}}</span>
							{{slotProps.data.fullName}}
						</template>
					</Column>
					<Column field="roles" :header="$t('user.roles')" :sortable="true" headerStyle="width:20%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('user.roles')}}</span>
							{{slotProps.data.roles}}
						</template>
					</Column>
					<Column field="organization" :header="$t('user.organization')" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('user.organization')}}</span>
							{{slotProps.data.organization}}
						</template>
					</Column>
					<Column field="last_logined" :header="$t('user.last_logined')" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('user.last_logined')}}</span>
							{{slotProps.data.last_logined}}
						</template>
					</Column>
					<Column field="description" :header="$t('user.description')" :sortable="true" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('user.description')}}</span>
							{{slotProps.data.description}}
						</template>
					</Column>
					<Column headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editEntity(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteEntity(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				-->
				<DialogFormStore :entity="currentEntity" :visible="entityDialog"
					@hide="entityDialog=false"
					@cancel="entityDialog=false"
					@save="saveEntity($event)"/>

				<dialog-confirm :visible="deleteEntityDialog"
					:header="$t('user.confirm_delete')"
					content = "Dialog content"
					@hide="deleteEntityDialog = false"
					@reject="deleteEntityDialog = false" @accept="deleteEntity"
				>
					<template #content>
						<div class="flex align-items-center justify-content-center">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span v-if="currentEntity">Are you sure you want to delete <b>{{currentEntity.email}}</b>?</span>
						</div>
					</template>
				</dialog-confirm>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import StoreService from '@/service/StoreService'
import DialogConfirm from '@/pages/common/DialogConfirm'
import CrudTable from '@/pages/common/CrudTable'
import DialogFormStore from './DialogFormStore'
export default {
	components: {CrudTable, DialogConfirm, DialogFormStore},
	data() {
		return {
			entities: [],
			selectedEntity: null,
			entityDialog: false,
			deleteEntityDialog: false,
			deleteEntitiesDialog: false,
			currentEntity: {},
			selectedEntities: null,
			filters: {},
			metaStoreTable: {
				entityName: "store",
					expander: false,
					searchable: true,
					paginator:{
						enable: true,
						rows: 10,
						currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} stores",
					},
					columns: [
						{
							name: "name",
							headerStyle: "width:15%; min-width:10rem;"
						},
						{
							name: "code",
							headerStyle: "width:10%; min-width:10rem;"
						},
						{
							name: "contactName",
							headerStyle: "width:15%; min-width:10rem;"
						},
						{
							name: "contactPhone",
							headerStyle: "width:10%; min-width:10rem;"
						},
						{
							name: "contactEmail",
							headerStyle: "width:10%; min-width:10rem;"
						},
						{
							name: "address",
							headerStyle: "width:25%; min-width:10rem;"
						},
						{
							name: "description",
							headerStyle: "width:25%; min-width:10rem;"
						}
					],
					tableActions: [
						{
							name: "create",
							icon: "pi pi-plus",
							style: "p-button-rounded p-button-success mr-2"
						}
					],
					itemActions: [
						{
							name: "edit",
							icon: "pi pi-pencil",
							style: "p-button-rounded p-button-success mr-2",
							condition: "mode != 'edit'"
						},
						{
							name: "save",
							icon: "pi pi-save",
							style: "p-button-rounded p-button-success mr-2",
							condition: "mode == 'edit'"
						},
						{
							name: "delete",
							icon: "pi pi-trash",
							style: "p-button-rounded p-button-warning mt-2",
							condition: "mode != 'edit'"
						}
					]
			}
		}
	},
	created() {
		this.initFilters();
	},
	mounted() {
		StoreService.getAll().then(data => {
			this.entities = data;
			this.entities.splice(0,0,{mode:'edit'})
		});
	},
	methods: {
		onTableAction(sAction) {
			console.log(sAction)
		},
		onItemAction(sAction, entity, index) {
			if (sAction =='save') {
				if (entity.id) {
					StoreService.update(entity).then(response => {
						this.entities[index] = response.data
					});
				} else {
					let data = {...entity};
					StoreService.create(data).then(response => {
						this.entities[index] = {mode:'edit'};
						this.entities.push(response.data);
					});
				}
			} else if (sAction == 'edit') {
				entity.mode = 'edit';
			} else if (sAction == 'delete') {
				StoreService.delete(entity.id).then(() => {
					this.entities.splice(index, 1);
				});
			}
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.currentEntity = {};
			this.entityDialog = true;
		},
		hideDialog() {
			this.entityDialog = false;
			this.submitted = false;
		},
		saveEntity(entity) {
			if (entity.email.trim()) {
				if (entity.id) {
					this.entities[this.findIndexById(entity.id)] = entity;
						StoreService.update(entity.id, entity).then((response) => {
							console.log(response);
							this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.updated'), life: 3000});
							this.currentEntity = response.data;
							this.entityDialog = false;
						})
				}
				else {
					StoreService.create(entity).then((response) => {
						this.currentEntity = response.data;
						this.entities.push(response.data);
						this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.created'), life: 3000});
						this.entityDialog = false;
					})
				}
				this.selectedEntity = {};
			}
		},
		editEntity(entity) {
			this.currentEntity = {...entity};
			this.entityDialog = true;
		},
		openConfirmDialog() {
        this.$confirm.require({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when user confirms the action
            },
            reject: () => {}
        });
    },
		confirmDeleteEntity(entity) {
			this.currentEntity = entity;
			this.deleteEntityDialog = true;
		},
		deleteEntity() {
			this.entities = this.entities.filter(val => val.id !== this.currentEntity.id);
			this.deleteEntityDialog = false;
			this.currentEntity = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.deleted'), life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.entities.length; i++) {
				if (this.entities[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteEntitiesDialog = true;
		},
		deleteSelectedEntities() {
			this.entities = this.entities.filter(val => !this.selectedEntities.includes(val));
			this.deleteEntitiesDialog = false;
			this.selectedEntities = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('user.multiple_deleted'), life: 3000});
		},
		initFilters() {
        this.filters = {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
    }
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/demo/badges.scss';
</style>
