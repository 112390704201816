<template>
  <Dialog :visible="containerVisible" :style="{width: '600px'}" :header="$t('user.dialog.form_header')" :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="field grid">
      <label for="email" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.email')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="email" v-model.trim="currentEntity.email" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.email}" />
        <small class="p-invalid" v-if="submitted && !currentEntity.email">{{$t('user.required_email')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="fullName" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.full_name')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="fullName" v-model.trim="currentEntity.fullName" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.fullName}" />
        <small class="p-invalid" v-if="submitted && !currentEntity.fullName">{{$t('user.required_full_name')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="password" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.password')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="password" v-model.trim="currentEntity.password" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.password}" type="password"/>
        <small class="p-invalid" v-if="submitted && !currentEntity.password">{{$t('user.required_password')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="password2" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.password2')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="password2" v-model.trim="currentEntity.password2" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.password2}" type="password"/>
        <small class="p-invalid" v-if="submitted && !currentEntity.password2">{{$t('user.required_password2')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="roles" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.roles')}}</label>
      <div class="col-12 md:col-10">
        <Dropdown id="roles" v-model="currentEntity.roles" :options="roles" optionLabel="label" :placeholder="$t('user.select_roles')">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0">Inventory Status</label>
      <div class="col-12 md:col-10">
        <Dropdown id="organization" v-model="currentEntity.organization" :options="partners" optionLabel="label" :placeholder="$t('user.select_organization')">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="description" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.description')}}</label>
      <div class="col-12 md:col-10">
        <Textarea id="description" v-model="currentEntity.description" required="false" rows="3" cols="20" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
export default {
  name:'form-store',
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {  }
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel','save','hide'],
  data() {

    return {
      submitted: false,
      currentEntity: this.entity,
      containerVisible: this.visible,
      roles: [],
      partners: []
    }
  },
  methods: {
    onCancel() {
      this.containerVisible = false;
      this.currentEntity = {};
      this.$emit('cancel');
    },
    onSave() {
      this.submitted = true;
      this.$emit('save', this.currentEntity);
    },
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  },
  updated() {
    this.currentEntity = this.entity;
    if (this.visible) {
        this.containerVisible = this.visible;
    }
  },
}
</script>
