import http, {Service} from './common'
const resource = 'stores'
class StoreService extends Service {
  getAll() {
    return http.get(`/${resource}/`).then(response => response.data || []);
  }

  get(id) {
    return http.get(`/${resource}/${id}`)
  }

  create(data) {
    return http.post(`/${resource}/`, data)
  }

  update(id, data) {
    return http.put(`/${resource}/${id}`, data)
  }

  delete(id) {
    return http.delete(`/${resource}/${id}`)
  }

  deleteAll() {
    return http.delete(`/${resource}`)
  }

  findByName(user) {
    return http.get(`/${resource}?name=${user}`)
  }
}

export default new StoreService(resource)
